<script>
  import { onMount, onDestroy } from 'svelte';
  import * as faceapi from 'face-api.js';

  let videoElement;
  let overlayCanvas;
  let isModelLoaded = false;
  let stream;
  let currentAudio = null;
  let animationInterval;
  let animationCanvas;
  let previousEmotion = '';
  let emotionChangeCooldown = false;
  let score = 0;
  let targetEmotion = '';
  let targetEmotionTimeout;

  // List of emotions
  const emotions = ['happy', 'sad', 'angry', 'surprised'];

  // Load face-api models and set up the webcam video feed
  async function startVideo() {
    try {
      await faceapi.nets.tinyFaceDetector.loadFromUri('/models');
      await faceapi.nets.faceLandmark68Net.loadFromUri('/models');
      await faceapi.nets.faceExpressionNet.loadFromUri('/models');
      isModelLoaded = true;
      
      stream = await navigator.mediaDevices.getUserMedia({ video: {} });
      videoElement.srcObject = stream;
      setNewTargetEmotion();
    } catch (err) {
      console.error('Failed to load models or access webcam: ', err);
    }
  }

  // Analyze facial gestures using face-api.js
  async function analyzeFace() {
    if (!isModelLoaded || videoElement.videoWidth === 0 || videoElement.videoHeight === 0) return;
    const options = new faceapi.TinyFaceDetectorOptions();
    const detection = await faceapi.detectSingleFace(videoElement, options)
                                   .withFaceLandmarks()
                                   .withFaceExpressions();
    if (detection) {
      // Draw overlay with landmarks and emotion
      const dims = {
        width: videoElement.clientWidth,
        height: videoElement.clientHeight
      };
      overlayCanvas.width = dims.width;
      overlayCanvas.height = dims.height;
      const resizedDetections = faceapi.resizeResults(detection, dims);
      const ctx = overlayCanvas.getContext('2d');
      ctx.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
      faceapi.draw.drawDetections(overlayCanvas, resizedDetections);
      faceapi.draw.drawFaceLandmarks(overlayCanvas, resizedDetections);
      faceapi.draw.drawFaceExpressions(overlayCanvas, resizedDetections);

      // Map emotions to different outputs
      const expressions = detection.expressions;
      const highestExpression = Object.keys(expressions).reduce((a, b) => expressions[a] > expressions[b] ? a : b);

      // Handle different expressions with visual or auditory feedback
      if (highestExpression !== previousEmotion && !emotionChangeCooldown) {
        previousEmotion = highestExpression;
        emotionChangeCooldown = true;
        setTimeout(() => {
          emotionChangeCooldown = false;
        }, 1000); // Cooldown period to prevent rapid emotion changes

        // Check if the detected emotion matches the target emotion
        if (highestExpression === targetEmotion) {
          score++;
          setNewTargetEmotion();
        }

        switch (highestExpression) {
          case 'happy':
            document.body.style.backgroundColor = 'lightyellow';
            stopSound();
            playSound('happy.mp3');
            startBackgroundAnimation('happy');
            break;
          case 'sad':
            document.body.style.backgroundColor = 'lightblue';
            stopSound();
            playSound('sad.mp3');
            startBackgroundAnimation('sad');
            break;
          case 'angry':
            document.body.style.backgroundColor = 'lightcoral';
            stopSound();
            playSound('angry.mp3');
            startBackgroundAnimation('angry');
            break;
          case 'surprised':
            document.body.style.backgroundColor = 'lightgreen';
            stopSound();
            playSound('surprised.mp3');
            startBackgroundAnimation('surprised');
            break;
          default:
            document.body.style.backgroundColor = 'white';
            stopSound();
            stopBackgroundAnimation();
            break;
        }
      }
    }
  }

  function setNewTargetEmotion() {
    clearTimeout(targetEmotionTimeout);
    targetEmotion = emotions[Math.floor(Math.random() * emotions.length)];
    document.getElementById('target-emotion').innerText = `Make a ${targetEmotion} face!`;
    targetEmotionTimeout = setTimeout(setNewTargetEmotion, 5000); // Change target emotion every 5 seconds
  }

  function playSound(soundFile) {
    stopSound(); // Ensure any current audio is stopped before playing a new one
    currentAudio = new Audio(`/sounds/${soundFile}`);
    currentAudio.loop = true; // Ensure sound plays longer
    currentAudio.addEventListener('canplaythrough', () => {
      if (currentAudio) {
        currentAudio.play().catch(err => {
          if (err.name !== 'AbortError') {
            console.error('Playback error:', err);
          }
        });
      }
    });
    currentAudio.addEventListener('error', () => {
      console.error('Failed to load or play sound:', soundFile);
    });
  }

  function stopSound() {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
      currentAudio = null;
    }
  }

  function startBackgroundAnimation(expression) {
    stopBackgroundAnimation();
    animationCanvas = document.createElement('canvas');
    const container = document.querySelector('main');
    container.appendChild(animationCanvas);
    animationCanvas.style.position = 'absolute';
    animationCanvas.style.top = 100;
    animationCanvas.style.left = 0;
    animationCanvas.style.width = '50%';
    animationCanvas.style.height = '50%';
    animationCanvas.style.pointerEvents = 'none';
    const ctx = animationCanvas.getContext('2d');
    const image = new Image();

    switch (expression) {
      case 'happy':
        image.src = '/emotions/happy.jpg';
        break;
      case 'sad':
        image.src = '/emotions/sad.jpg';
        break;
      case 'angry':
        image.src = '/emotions/angry.jpg';
        break;
      case 'surprised':
        image.src = '/emotions/surprised.jpg';
        break;
      default:
        return;
    }

    function draw() {
      ctx.clearRect(0, 0, animationCanvas.width, animationCanvas.height);
      ctx.globalAlpha = 0.5; // Set transparency level
      ctx.drawImage(image, 0, 0, animationCanvas.width, animationCanvas.height); // Draw image to fit entire canvas
    }

    image.onload = () => {
      animationInterval = setInterval(draw, 100);
    };
  }

  function stopBackgroundAnimation() {
    if (animationInterval) {
      clearInterval(animationInterval);
      animationInterval = null;
      if (animationCanvas) {
        const container = document.querySelector('main');
        container.removeChild(animationCanvas);
        animationCanvas = null;
      }
    }
  }

  onMount(() => {
    startVideo();
    videoElement.addEventListener('play', () => {
      setInterval(analyzeFace, 100); // Analyze every 100ms
    });
  });

  onDestroy(() => {
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
    }
    stopSound();
    stopBackgroundAnimation();
  });
</script>

<style>
  .video-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
    padding: 0 1rem;
    box-sizing: border-box;
  }

  video, canvas.overlay {
    width: 100%;
    height: auto;
    max-width: 100%;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 8px;
  }

  canvas.overlay {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }
</style>

<main>
  <div class="video-container">
    <video bind:this={videoElement} autoplay muted playsinline></video>
    <canvas bind:this={overlayCanvas} class="overlay"></canvas>
  </div>
  <p id="target-emotion">Make a face to start the game!</p>
  <p>Score: {score}</p>
</main>
