<script>
  export let title = "FanRow";
  import { Link } from 'svelte-routing';
</script>

<header class="bg-gray-800 text-white p-4 text-center">
  <h1 class="font-orbitron font-bold text-3xl">{title}</h1>
  <nav class="mt-2">
    <Link to="/" class="nav-link text-blue-300 hover:text-blue-500 font-bold mx-4">Home</Link>
    <Link to="/about" class="nav-link text-blue-300 hover:text-blue-500 font-bold mx-4">About</Link>
  </nav>
</header>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');
  .font-orbitron {
    font-family: 'Orbitron', sans-serif;
  }
  
  header {
    background: #a0a0a0;
    color: white;
    padding: 1rem;
    text-align: center;
  }

  nav {
    margin-top: 0.5rem;
  }

  h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 2.5rem;
  }
  
</style>
