<script>
  import { onMount, onDestroy } from 'svelte';
  import * as tf from '@tensorflow/tfjs';
  import '@tensorflow/tfjs-backend-webgl';
  import * as poseDetection from '@tensorflow-models/pose-detection';
  import * as THREE from 'three';

  let videoElement;
  let overlayCanvas;
  let isModelLoaded = false;
  let stream;
  let detector;
  let scene, camera, renderer, cube;
  let canvas;

  async function startVideo() {
    try {
      stream = await navigator.mediaDevices.getUserMedia({ video: {} });
      videoElement.srcObject = stream;
      await tf.ready();
      await tf.setBackend('webgl');
      detector = await poseDetection.createDetector(poseDetection.SupportedModels.MoveNet, {
        modelType: poseDetection.movenet.modelType.SINGLEPOSE_LIGHTNING
      });
      isModelLoaded = true;
    } catch (err) {
      console.error('Failed to load models or access webcam: ', err);
    }
  }

  async function detectPose() {
    if (!isModelLoaded || !videoElement || videoElement.videoWidth === 0 || videoElement.videoHeight === 0) return;
    try {
      const poses = await detector.estimatePoses(videoElement, { flipHorizontal: false });

      if (poses.length > 0) {
        const keypoints = poses[0].keypoints;
        const rightWrist = keypoints.find(point => point.name === 'right_wrist' || point.part === 'right_wrist');
        if (rightWrist && rightWrist.score > 0.5) {
          moveCube(rightWrist.x, rightWrist.y);
        }
      }
    } catch (err) {
      console.error('Error during pose detection: ', err);
    }
  }

  function moveCube(x, y) {
    if (cube && camera) {
      const normalizedX = (x / videoElement.videoWidth) * 2 - 1;
      const normalizedY = -(y / videoElement.videoHeight) * 2 + 1;
      cube.position.x = normalizedX * 5;
      cube.position.y = normalizedY * 5;
    }
  }

  function cleanupScene() {
    if (renderer) {
      renderer.dispose();
    }
    if (scene && cube) {
      scene.remove(cube);
      if (cube.geometry) cube.geometry.dispose();
      if (cube.material) cube.material.dispose();
    }
    scene = null;
    camera = null;
    renderer = null;
    cube = null;
  }

  function setupScene() {
    renderer = new THREE.WebGLRenderer({ canvas });
    renderer.setSize(window.innerWidth - 36, window.innerHeight);

    scene = new THREE.Scene();
    camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.z = 5;

    const geometry = new THREE.BoxGeometry();
    const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
    cube = new THREE.Mesh(geometry, material);

    scene.add(cube);
    animate();
  }

  function animate() {
    if (!cube) return; // Check if cube exists before rendering
    requestAnimationFrame(animate);
    renderer.render(scene, camera);
  }

  onMount(() => {
    setupScene();
    startVideo();
    const interval = setInterval(() => {
      if (videoElement && videoElement.readyState >= 2) {
        videoElement.play();
        clearInterval(interval);
        setInterval(detectPose, 100); // Detect pose every 100ms
      }
    }, 100);
  });

  onDestroy(() => {
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
    }
    cleanupScene();
  });
</script>

<style>
  .video-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
    padding: 0 1rem;
    box-sizing: border-box;
  }

  video, canvas.overlay {
    width: 100%;
    height: auto;
    max-width: 100%;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 8px;
  }

  canvas.overlay {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  canvas#canvas {
    display: block;
    margin: 0 auto;
    border: 1px solid #61dafb;
  }
</style>

<main>
  <div class="video-container">
    <video bind:this={videoElement} autoplay muted playsinline></video>
    <canvas bind:this={overlayCanvas} class="overlay"></canvas>
    <canvas bind:this={canvas} id="canvas"></canvas>
  </div>
  <p>Move your hand to control the 3D cube!</p>
</main>
