<script>
  import { outputMode, outputContent } from '../stores/store.js';
  import { onMount } from 'svelte';

  // Original story definition for resetting
  const initialStory = [
    { text: "Once upon a time, there was a traveler named HATENABITO. One day, while on a journey through a vibrant city, he boarded a ", placeholder: true, key: "Night Flight", imageUrl: "/images/night_flight.jpg", audioUrl: "/audio/nightflight.mp3" },
    { text: " to a distant land. As the plane soared above the clouds, he gazed out the window, feeling the ", placeholder: true, key: "Magic of Love", imageUrl: "/images/magic_of_love.jpg", audioUrl: "/audio/magicoflove.mp3" },
    { text: " in the air. When he arrived, he met TWO extraordinary friends who were known as ", placeholder: true, key: "Dream Fighter", imageUrl: "/images/dream_fighter.jpg", audioUrl: "/audio/dreamfighter.mp3" },
    { text: ". Together, they explored the bustling streets and stumbled upon a dazzling ", placeholder: true, key: "Chocolate Disco", imageUrl: "/images/chocolate_disco.jpg", audioUrl: "/audio/chocolatedisco.mp3" },
    { text: " where everyone danced under shimmering lights. The disco's DJ played a mix of ", placeholder: true, key: "Polyrhythm", imageUrl: "/images/polyrhythm.jpg", audioUrl: "/audio/polyrhythm.mp3" },
    { text: " beats that made them feel truly alive. The next day, they wandered into a quiet park to ", placeholder: true, key: "Relax In The City", imageUrl: "/images/relax_in_the_city.jpg", audioUrl: "/audio/relaxinthecity.mp3" },
    { text: ", where the soft breeze carried the faint echoes of a ", placeholder: true, key: "Star Train", imageUrl: "/images/star_train.jpg", audioUrl: "/audio/startrain.mp3" },
    { text: " passing by. Suddenly, they heard an enchanting melody and followed it, finding themselves at a hidden temple celebrating the ", placeholder: true, key: "Spring of Life", imageUrl: "/images/spring_of_life.jpg", audioUrl: "/audio/springoflife.mp3" },
    { text: ". The air was filled with the sound of bells and laughter, reminding them to treasure every moment. Later, they climbed to the top of a hill to watch the sunset, marveling at the ", placeholder: true, key: "Glitter", imageUrl: "/images/glitter.jpg", audioUrl: "/audio/glitter.mp3" },
    { text: " of the horizon. There, HATENABITO shared his dream of creating something beautiful and meaningful, inspired by the ", placeholder: true, key: "Cling Cling", imageUrl: "/images/cling_cling.jpg", audioUrl: "/audio/clingcling.mp3" },
    { text: " of an idea that had been lingering in his mind. His friends cheered him on, promising to support him every step of the way. As night fell, they returned to the city, their hearts brimming with joy and memories. They ended their adventure with one last dance at a rooftop party, surrounded by dazzling lights and the vibrant energy of the ", placeholder: true, key: "Party Maker", imageUrl: "/images/party_maker.jpg", audioUrl: "/audio/partymaker.mp3" },
    { text: ".", placeholder: false }
  ];

  let story = JSON.parse(JSON.stringify(initialStory));
  
  let songTitles = shuffleArray(["Night Flight", "Magic of Love", "Dream Fighter", "Chocolate Disco", "Polyrhythm", "Relax In The City", "Star Train", "Spring of Life", "Glitter", "Cling Cling", "Party Maker"]);
  let currentIndex = 0;  
  
  let audioRight = new Audio("/audio/ya.mp3");
  let audioWrong = new Audio("/audio/laugh.mp3");
  let audioFinish = new Audio("/audio/hatenabito.mp3");
  
  let audioPlayer;
  let isAudioOn = false;
  let showInstruction = true;
  
  function toggleAudio() {
    isAudioOn = !isAudioOn;
  
    if (isAudioOn) {
      playCurrentAudio(); // Ensure current audio plays when toggled ON
    } else if (audioPlayer && !audioPlayer.paused) {
      audioPlayer.pause(); // Pause the audio when toggled OFF
    }
  
    console.log("Audio is now", isAudioOn ? "ON" : "OFF");
  } 

  function handleSongClick(song) {
    if (currentIndex === 0) {
      showInstruction = false;
    }
    if (song === story[currentIndex].key) {
      story[currentIndex].placeholder = false;
      songTitles = songTitles.filter(title => title !== song);
      currentIndex++;
      playAudio(audioRight);
	  playCurrentAudio();

      if (currentIndex === story.length - 1) {
		playAudio(audioFinish);
        alert("Congratulations! You've completed the story!");
      }
    } else {
	  playAudio(audioWrong);
	  alert("Wrong choice! Starting over...");
      resetStory();
    }
  }
  
  function playCurrentAudio() {
	console.log("Current Index:", currentIndex);
    const currentPart = story[currentIndex];
	if (audioPlayer) {
	    audioPlayer.pause();
		audioPlayer.currentTime = 0;
	}		
    if (isAudioOn && currentPart && currentPart.audioUrl) {
      audioPlayer.src = currentPart.audioUrl;
	  audioPlayer.play().catch((err) => {
        console.error("Audio playback failed:", err);
      });
    }
  }
    
  function playAudio(audio) {
    if (isAudioOn) {
	  audio.currentTime = 0;
	  audio.play();	  
    }
  }

  // Function to reset the story immediately
  function resetStory() {
    story = JSON.parse(JSON.stringify(initialStory));
    currentIndex = 0;
    songTitles = shuffleArray(["Night Flight", "Magic of Love", "Dream Fighter", "Chocolate Disco", "Polyrhythm", "Relax In The City", "Star Train", "Spring of Life", "Glitter", "Cling Cling", "Party Maker"]);
	playCurrentAudio();
  }
  
  onMount(() => {
    playCurrentAudio();
  });

  // Function to shuffle an array
  function shuffleArray(array) {
    let shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  }
</script>

<style>
  .story-output {
    text-align: center;
    padding: 20px;
    font-size: 20px;
    color: #333;
  }

  .story {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
  }

  .story-part {
    margin-bottom: 20px;
  }

  .placeholder {
    color: #888;
    font-style: italic;
    cursor: pointer;
    padding: 0 5px;
  }

  .instruction {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 30px;
	font-size: 18px;
	color: #007BFF;
  }

  .song-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .song-item {
    padding: 10px 15px;
    cursor: pointer;
    color: blue;
    text-decoration: underline;
    background: #f0f0f0;
    border-radius: 5px;
  }

  .song-item:hover {
    color: darkblue;
    background: #e0e0e0;
  }

  .image-placeholder {
    width: auto;
    height: auto;
    max-width: 200px;
    max-height: 200px;
    margin-top: 10px;
    vertical-align: middle;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .filled {
    font-weight: bold;
  }
  
  .audio-toggle {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .audio-toggle button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .audio-toggle button:hover {
    background-color: #0056b3;
  }  
</style>

<audio bind:this={audioPlayer}></audio>

<!-- Song Titles List: Now at the top to avoid scrolling down -->
<div class="audio-toggle">
  <button on:click={toggleAudio}>
    {isAudioOn ? "Turn Audio Off" : "Turn Audio On"}
  </button>
</div>
{#if showInstruction}
  <div class="instruction">
      Click the song titles in the correct order to match the photos!
  </div>
{/if}
<div class="song-list">
  {#each songTitles as song}
    <div
      class="song-item"
      on:click={() => handleSongClick(song)}
    >
      {song}
    </div>
  {/each}
</div>

<!-- Story Section: Below the Song List -->
<div class="story-output">
  <div class="story">
    {#each story as part, index}
      <div class="story-part">
        <span>{part.text}</span>
        {#if part.placeholder}
          <img class="image-placeholder" src={part.imageUrl} alt="placeholder image" />
        {:else if part.key}
          <span class="filled">{part.key}</span>
        {/if}
      </div>
    {/each}
  </div>
</div>
