<script>
  import Header from './components/Header.svelte';
  import Home from './components/Home.svelte';
  import About from './components/About.svelte';
  import { Router, Route } from 'svelte-routing';
  import GestureInput from './inputModules/GestureInput.svelte';
  import VisualOutput from './outputModules/VisualOutput.svelte';
  import StoryTellerOutput from './outputModules/StoryTellerOutput.svelte';
  import { outputMode } from './stores/store.js';
  
  let currentMode;
  $: currentMode = $outputMode;
</script>

<!-- Der Router muss den gesamten Bereich umschließen -->
<Router>
  <Header title="fan row" />

  <!-- Definiere die Route zur Startseite -->
  <Route path="/" let:location>
    <main>
      <!--<h1 class="text-4xl font-bold text-blue-500 text-center">Welcome to FanRow</h1>-->
      <div class="flex space-x-4 justify-center my-4">
        <button on:click={() => outputMode.set('storyTeller')} class="btn">Story Teller</button>
		<button on:click={() => outputMode.set('gesture')} class="btn">Face</button>
		<button on:click={() => outputMode.set('motion')} class="btn">Motion</button>
      </div>

      {#if currentMode === 'gesture'}
        <section class="flex flex-col space-y-4 items-center my-4">
		  {#key currentMode}
		    <GestureInput />
		  {/key}
        </section>
      {/if}

      {#if currentMode === 'motion'}
        <section class="flex flex-col space-y-4 items-center my-4">
		  {#key currentMode}
		    <VisualOutput />
		  {/key}			
        </section>
      {/if}

      {#if currentMode === 'storyTeller'}
        <section class="flex flex-col space-y-4 items-center">
		  {#key currentMode}
            <StoryTellerOutput />
		  {/key}
        </section>
      {/if}
    </main>
  </Route>

  <!-- Definiere die Route zur About-Seite -->
  <Route path="/about" component={About} />
</Router>

<style>
  main {
    padding: 20px;
  }

  .btn {
    background-color: #282c34;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    border-radius: 5px;
  }

  .btn:hover {
    background-color: #3b3f47;
  }
</style>
