<script>
  // Optional: You can add any JavaScript needed here for interaction or logic
</script>

<main>
  <h1 class="text-4xl font-bold text-center my-8">About fan row</h1>
  <section class="text-lg text-gray-700 px-8">
    <p>
      <strong>fan row</strong> is more than just a platform; it's an immersive experience designed to bridge the gap between fans and their favorite artists. Our vision is to create an innovative and engaging platform that combines multiple interaction methods, making it feel as if you are front-row at a live event, even from the comfort of your own home. We aim to redefine how fans experience music, dance, and interactions with artists by leveraging advanced technologies such as motion detection, gesture control, and virtual reality.
    </p>
    <h2 class="text-2xl font-bold my-4">The Vision</h2>
    <p>
      The core idea behind <strong>fan row</strong> is to bring together fans and artists through an innovative, interactive experience. Imagine joining a live event virtually, seeing your favorite performers up close, and even interacting with them in real time. This isn’t just a streaming service – it’s a deeply engaging, multi-sensory event. Our system will allow fans to dance along with their artists, follow choreography, and receive real-time feedback on their movements. In the future, we plan to make this experience even more immersive by introducing haptic feedback, so users can truly feel a connection with the performance.
    </p>
    <h2 class="text-2xl font-bold my-4">Business Concept</h2>
    <p>
      <strong>fan row</strong> will serve as a platform to deliver exclusive live experiences, training sessions, and community events for fanbases of different artists and performers. The monetization model will include:
    </p>
    <ul class="list-disc list-inside ml-4">
      <li><strong>Subscription Plans</strong>: Offering premium access to exclusive virtual concerts, events, and training sessions.</li>
      <li><strong>Virtual Merch and NFT Collectibles</strong>: Fans will have the opportunity to purchase unique virtual merchandise, such as digital outfits and collectibles inspired by their favorite artists.</li>
      <li><strong>Skill Training and Dance Sessions</strong>: Paid workshops where fans can learn dances, practice choreography, and get real-time feedback from both AI trainers and even the virtual representation of artists.</li>
    </ul>
    <h2 class="text-2xl font-bold my-4">Technology Behind <strong>fan row</strong></h2>
    <p>
      <strong>fan row</strong> utilizes cutting-edge technologies like MoveNet for motion detection and Svelte for a seamless, responsive web experience. Our MVP includes features such as:
    </p>
    <ul class="list-disc list-inside ml-4">
      <li><strong>Motion and Gesture Control</strong>: Detecting fan movements and synchronizing them with virtual artist performances.</li>
      <li><strong>Speech Recognition and Voice Interaction</strong>: Allowing users to control their experience with voice commands and interact naturally with virtual performers.</li>
      <li><strong>Virtual Trainers</strong>: We aim to create virtual trainers that teach fans choreography in a fun, engaging way, using real-time feedback and AI analysis to improve users' skills.</li>
    </ul>
    <h2 class="text-2xl font-bold my-4">Long-Term Goals</h2>
    <p>
      Our long-term vision involves providing an experience that combines multiple sensory inputs. Imagine using haptic devices to feel the beat of the music or the movements of your favorite artist. We're also working towards creating a marketplace for AI agents, where multiple agents with different capabilities can seamlessly interact and support each other. This will enhance the quality of the experience and introduce more interactive features, such as multi-agent collaboration to evaluate and guide the fan’s learning process.
    </p>
    <p>
      <strong>fan row</strong> isn’t just about watching – it’s about connecting, participating, and feeling like a true part of the performance. We’re pushing the boundaries of what a fan experience can be, and we’re excited to bring this journey to life.
    </p>
  </section>
</main>

<style>
  main {
    padding: 20px;
  }

  h1 {
    color: #282c34;
  }

  section {
    max-width: 800px;
    margin: 0 auto;
  }
</style>
